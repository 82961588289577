.social-box {
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  svg {
    @apply w-8;
  }
  &:after {
    content: '';
    @apply absolute left-0 top-0 z-0 h-full w-full;
    transform: scaleX(0);
    transform-origin: center left;
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover:after {
    transform: scaleX(1);
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover svg {
    fill: #000;
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
